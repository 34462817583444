.loading-card {
    width: 300px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  
  .loading-image {
    width: 100%;
    height: 120px;
    background-color: #e0e0e0;
    border-radius: 5px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  .loading-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .loading-line {
    width: 100%;
    height: 15px;
    background-color: #e0e0e0;
    border-radius: 5px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  .loading-line.short {
    width: 70%;
  }
  
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #d0d0d0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  