
:root {
  --nav-color: #2c4964;

  --nav-hover-color: #073980;

  --nav-mobile-background-color: #ffffff;

  --nav-dropdown-background-color: #ffffff;
 
  --nav-dropdown-color: #2c4964;

  --nav-dropdown-hover-color: #1977cc;

}
html {
  scroll-behavior: smooth;
}
.breadcrubtext{
  color: #073980;
  font-weight: 600;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}
/*--------------------------------------------------------------
  # General Styling & Shared Classes
  --------------------------------------------------------------*/


/* Pulsating Play Button
  ------------------------------*/
.pulsating-play-btn {
  width: 94px;
  height: 94px;
  background: #073980;
  border-radius: 50%;
  display: block;
  position: relative;
  overflow: hidden;
}

.pulsating-play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-play-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid #dbe3e3;
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.pulsating-play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.pulsating-play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.pulsating-play-btn:hover:after {
  border-left: 15px solid var(--accent-color);
  transform: scale(20);
}

@keyframes pulsate-play-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/* PHP Email Form Messages
  ------------------------------*/
.php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: var(--surface-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--surface-color);
  animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Variables
--------------------------------------------------------------*/
:root {
  --primary-color: #073980;
  --contrast-color: #e0e0d7;
  --white-color: #fff;
  --black-color: #000;
  --nav-color: #073980;
  --nav-hover-color: #000;
  --nav-dropdown-background-color: #fff;
  --nav-dropdown-color: #073980;
  --nav-dropdown-hover-color: #000;
  --nav-mobile-background-color: #fff;
  --accent-color: #f3f4f5;
  --default-color: #333;
}
.sliderimages{
  height: 470px !important;
}
/*--------------------------------------------------------------
  # Top Bar
--------------------------------------------------------------*/
.topbar {
  background-color: var(--primary-color);
  height: 40px;
  padding: 0;
  font-size: 14px;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica;
}
.topbar .contact-info {
  color: var(--white-color);
  display: flex;
  align-items: center;
}
.topbar .contact-info a,
.topbar .contact-info span,
.topbar .social-links a {
  color: var(--white-color) !important;
}
.topbar .contact-info svg,
.topbar .social-links svg {
  color: var(--white-color);
  font-size: 1.2em;
}
.topbar .social-links a {
  margin-left: 20px;
  color: var(--white-color);
  transition: color 0.3s;
}
.topbar .social-links a:hover {
  color: var(--contrast-color);
}
@media (min-width: 320px) and (max-width: 767px) {
  .topbar .contact-info a,
  .topbar .contact-info span {
    padding-left: 8px !important;
    font-size: 12px;
  }
  .sliderimages {
    height: 150px !important;
  }
}




/* Feedback card styles */
.topcard {
  width: 24rem !important;
  height: 22rem !important;
  border-top: 1rem solid #073980 !important;
}

/* Feedback text */
.feedtext {
  font-size: 16px !important;
}

/* Card content styling */
.ourcardtext {
  flex: 1;
  text-align: center;
  padding: 0rem 2rem;
  font-size: 0.8rem;
  color: rgb(82, 81, 81);
  line-height: 1.0rem;
  max-height: 14rem; 
  overflow: hidden; 
}

/* Card footer */
.ourcardfooter {
  border-top: none;
  text-align: center;
  font-family: 'Varela Round';
}

/* Column styling */
.colstyle {
  padding: 0 0.5rem;
}

/* Card title styling */
.ourcardtitle {
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: bold;
  font-family: 'IM Fell French Canon SC';
}

/* Service heading */
.serviceheadingfeed {
  font-size: 18px;
  color: #073980;
  font-weight: 600 !important;
  font-family: Helvetica;
}

/* Name styling */
#name {
  font-size: 0.8rem;
  font-weight: bold;
}

/* Position styling */
#position {
  font-size: 0.7rem;
}





/*--------------------------------------------------------------
  # Header Branding
--------------------------------------------------------------*/
.header {
  width: 100%;
  background-color: var(--white-color);
  transition: all 0.5s;
  font-family: Helvetica;
}

.header .branding {
  min-height: 60px;
  padding: 0px 0;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  max-height: 86px;
}

.cta-btn {
  color: var(--white-color);
  background: var(--primary-color);
  font-size: 15px;
  padding: 8px 25px;
  margin-left: 30px;
  border-radius: 50px;
  transition: background 0.3s, color 0.3s;
}

.header .cta-btn:hover {
  color: var(--black-color);
  background: var(--contrast-color);
  text-decoration: none;
  font-weight: 600;
}

.dropdown-menu {
  background-color: #073980 !important;
  margin-top: 1px !important;
  color: #fff !important;
  border: none;
}

.dropdown-menu .dropdown-item {
  color: #fff !important;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #fff !important;
  color: #073980 !important;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .header .cta-btn {
    margin-right: 15px;
    padding: 6px 15px;
  }
}

/*--------------------------------------------------------------
  # Navigation Menu
--------------------------------------------------------------*/
/* Desktop and Mobile Navigation */
.navmenu {
  padding: 0;
  display: flex;
  align-items: center;
}

.navmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.navmenu li {
  position: relative;
  padding: 2px 15px;
}

.navmenu .mymenu {
  color: var(--nav-color);
  font-size: 18px;
  font-family: Helvetica;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: color 0.5s;
}

.navmenu .mymenu:hover,
.navmenu .active {
  font-weight: 700 !important;
  color: var(--nav-hover-color);
  transition: 0.5s;
}

.navmenu .dropdown ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 10px;
  padding: 5px 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #073980;
  transition: visibility 0.3s, opacity 0.3s;
}

.navmenu .dropdown.active ul {
  display: block;
  visibility: visible;
  opacity: 1;
}

/* Mobile Navigation */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    transition: color 0.3s;
    cursor: pointer;
  }

  .navmenu {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: var(--nav-mobile-background-color);
    padding: 10px 0px !important;
    margin: 0;
    border-radius: 6px;
    z-index: 9998;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .navmenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-direction: column;
  }

  .navmenu a {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    display: block;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
  }

  .navmenu .dropdown ul {
    display: none;
    background-color: var(--nav-dropdown-background-color);
    padding: 10px 0;
  }

  .navmenu .dropdown.active ul {
    display: block;
    background-color: var(--nav-dropdown-background-color);
    transition: all 0.5s ease-in-out;
  }

  .mobile-nav-active .navmenu {
    display: block;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: var(--white-color);
  }
}

/*--------------------------------------------------------------
  # Scroll Effect
--------------------------------------------------------------*/
.scrolled .header {
  box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1) !important;
}

.scrolled .header .topbar {
  height: 0 !important;
  visibility: hidden !important;
  overflow: hidden !important;
}


/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  color: #000;
  background-color: var(--background-color);
  border-top: 3px solid #073980;
  font-size: 16px;
  position: relative;
}
.footericons{
  color: #073980 !important;
  font-weight: 800 !important;
}
.footer .footer-top {
  padding-top: 50px;
}

.footer .footer-about .logo {
  margin-bottom: 25px;
}
.footer .footer-about .logo span {
  color: #073980;
  font-family: var(--heading-font);
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
}
.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
  font-weight: 400;
}
.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #073980;
  font-size: 16px;
  font-weight: 400;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: #073980;
  font-weight: 600;
}

.footer h4 {
  font-size: 20px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
  color: #073980;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  padding-right: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: #073980;
  font-weight: 800;
}

.footer .footer-contact p {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
}

.footer .copyright {
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #073980;
  color: #fff;
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 4px;
  font-size: 14px;
}

.aboutheading {
  padding-top:25px !important ;
  color: #073980;
  font-family: Helvetica;
  font-weight: 600;
  font-size: 28px;
}
.aboutheading1{
  color: #073980;
  font-family: Helvetica;
  font-weight: 600;
  font-size: 34px;
}

.subheading {
  color: #073980;
  font-family: Helvetica;
  font-weight: 600;
}

.aboutp {
  font-family: inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.9;
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .service-item {
  border-radius: 10px;
  text-align: left;
  padding: 50px 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.services .service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}


.services .icon {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.services .service-item .icon i {
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .service-item h3 {
  color: #000;
  font-weight: 700;
  margin: 10px 0 10px 0;
  font-size: 22px;
  font-family: Helvetica;
}

.services .service-item p {
  font-family: inter;
  line-height: 1.7;
  font-size: 16px;
  margin-bottom: 0;
}



/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.pathakjiintro{
  font-family: Inter;
  font-size: 16px;
  line-height: 1.7;
}
.about .content h3 {
  font-size: 2rem;
  font-weight: 700;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}

.about .content ul i {
  flex-shrink: 0;
  font-size: 48px;
  color: var(--accent-color);
  margin-right: 20px;
}

.about .content ul h5 {
  font-size: 18px;
  font-weight: 700;
}

.about .content ul p {
  font-size: 15px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .pulsating-play-btn {
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
}
.aboutvideo{
  width: 600px !important;
  height: 500px !important;
}
.contactinfo {
  background-color: #073980;
}

.contacticon {
  color: #fff;
  font-size: 22px;
}

.submitbtn {
  background-color: #073980;
  color: #fff;
  font-size: 22px;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
}

/* user */
.maincontainer {
  height: 84vh;
  background: url('./Assets/images/loginbcover.webp') no-repeat center center/cover;
}
.myprofilebg{
  background: #e0e0d7;
}
.bg-light {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.loginbtn {
  background-color: #073980;
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  border: none;

}
.rbtn {
  color: #073980;
  font-weight: 600;
  font-size: 20px;
}
.myordercard{
  border: none !important;
}
  .myorderpimg{
    width: 100px;
    height: 100px;
  }

.myordercardheader{
  background-color: #073980 !important;
  font-size: 20px;
  font-weight: 600;
  font-family: Helvetica;
  color: #fff !important;
  border-radius: 0px !important;
}
.myordercardfooter{
  border: none !important;
  background: white !important;
}
.dashed-hr {
  border: 0 !important; 
  border-top: 3px dashed #00264d !important; /* Darker blue color */
  margin-bottom: 50px !important;
}
.bannerimges{
  height: 350px !important;
  width: 100%;
}

/* General product card styles */
.product {
  box-shadow: none;
  overflow: hidden; 
  border-bottom-left-radius: 10px !important; 
  border-bottom-right-radius: 10px !important;
  transition: all 0.3s ease;
}

.productlink {
  color: #073980 !important;
}
.pcardbody {
  background: #073980;
  color: white !important;
}
/* Hover effect for the product card */
.product:hover {
  transform: translateY(-10px);
}
.product-image {
  height: auto !important; 
  width: 100% !important;
  object-fit: contain; 
  max-height: 300px !important; 
  transition: transform 0.3s ease;
}

/* Product button */
.productbtn {
  background-color: #e0e0d7;
  color: #000;
  border-radius: 25px;
  border: none;
  padding: 6px 35px;
  transition: background-color 0.3s ease;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .product-image {
    max-height: 220px; 
  }
  .pcardbody {
    padding: 6px;
  }
}
@media (max-width: 576px) {
  .product-image {
    max-height: 180px; 
  }
  .pcardbody {
    padding: 4px;
  }
  .logo {
    max-height: 66px;
  }

}
  @media (max-width: 576px) {
    .topcard {
      width: 100% !important; /* Adjust to full width on mobile */
      margin: 1rem 0;
      min-height: 20rem !important; /* Adjust height to fit content */
    }
    .ourcardtext {
      padding: 0px 1rem; /* Reduce padding for smaller screens */
      font-size: 0.9rem; /* Adjust font size for better readability */
    }
    .feedtext {
      font-size: 14px !important; /* Make text slightly smaller on mobile */
      line-height: 1.0rem;
    }
    .serviceheadingfeed {
      font-size: 20px !important;
      padding-top: 15px !important;
    }
    .carousel-control-prev,
    .carousel-control-next {
      width: 10%; /* Make carousel controls smaller on mobile */
    }
  }
  
  

.similarp{
  color: #073980 !important;
  font-family: Helvetica;
  font-weight: 600 !important;
  font-size: 28px !important;
}
.socailmediaicons{
  color: #fff;
}
.socailmediaicons:hover{
  color: #000;
}



/* Input fields */
input {
  border: none;
  outline: none;
}

input:hover,
input:active,
input:focus {
  border: none;
  outline: none;
}

/* Search input field */
.sinput {
  border: 2px solid #073980;
}

/* Search icon */
.searchicon {
  color: #073980;
  font-weight: 900;
}

/* productdetails */
 .medicinetitle{
  font-size: 28px;
  font-family: Helvetica;
  font-weight: 600 !important;
  color: #073980;
 }
 .instoke{
  color: #073980;
  font-weight: 800 !important;
 }
 .rs{
  font-weight: 800 !important;
  font-size: 20px;
 }

 .medidetailtext{
  font-family: Inter;
  font-size: 18px;
  line-height: 1.7;
 }
.pdetailshr {
  background: #073980;
}
.medidetailproduct {
  width: 100%;
  max-width: 330px;
  max-height: 520px;
  height: auto !important;
  transition: all 0.5s ease;
  object-fit: cover;
}


.bottlesize {
  background: #073980;
  color: #fff;
  font-weight: 600;
  border: none;
}

.addtocart {
  background: #073980;
  color: #fff;
  font-weight: 600;
}

.addtocart:hover {
  background: #073980;
  color: #fff;
  font-weight: 800;
}

.downloadpdf {
  background: #073980;
  color: #fff;
  font-weight: 600;
}

.downloadpdf:hover {
  background: #073980;
  color: #fff;
  font-weight: 800;
}

.carouselbtn {
  background: #073980;
  color: #fff;
  border: none;
  padding: 7px 10px;
  border-radius: 5px;
}
/* General Services Card */
.services {
  background: #fff;
  box-shadow: none;
  transition: all 0.3s ease;
}

.scardbody {
  background: #073980;
  color: #fff !important;
  box-shadow: none;
  transition: background-color 0.3s ease;
}

.services:hover .scardbody {
  background: #e0e0d7;
  color: black !important;
}

.servicescard {
  height: auto !important; 
  width: 100% !important;  
  object-fit: cover; 
  max-height: 300px !important; /* Set a fixed max height for larger screens */
  aspect-ratio: 1 / 1; /* Ensure equal height and width for square images */
}

/* Ensure the images inside the cards maintain equal height and width */
.socail-image {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important; 
  transition: transform 0.3s ease;
}

/* Button styles */
.servicesbtn {
  background-color: #e0e0d7;
  color: #000;
  border-radius: 25px;
  border: none;
  padding: 6px 50px;
  transition: background-color 0.3s ease;
}

.services:hover .servicesbtn {
  background-color: #073980;
  color: white;
}

/* Icon Overlay */
.icon-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
}

.socialmediaicons {
  color: #fff;
  transition: color 0.3s ease;
}

.socailmediaicons:hover {
  color: #073980;
}



@media (max-width: 576px) {
  .servicescard {
    max-height: 150px; /* Further reduce image size for smaller screens */
  }

  .socail-image {
    max-height: 150px;
  }
}


/*--------------------------------------------------------------
# Quickinfo Section
--------------------------------------------------------------*/
.quickinfo .quickinfo-item {
  text-align: left;
  padding: 50px 25px;
  transition: all ease-in-out 0.3s;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.missioncard {
  background-color: #e0e0d7;
}
.visioncard {
  background-color: #073980;
}

.quickinfo .icon {
  color: #073980;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.quickinfo .quickinfo-item .icon i {
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.quickinfo .quickinfo-item h3 {
  color: #000;
  font-weight: 700;
  margin: 10px 0 10px 0;
  font-size: 22px;
  font-family: Helvetica;
}

.quickinfo .quickinfo-item p {
  font-family: inter;
  line-height: 1.7;
  font-size: 16px;
  margin-bottom: 0;
}

.quickinfobtn {
  background-color: #073980;
  color: #fff;
  padding: 10px 85px;
  border: none;
  font-size: 24px;
  font-weight: 600;
}
/* services page */
.serviceimgages {
  text-align: center;
  width: 500px;
  height: 300px;
}

.pathakjibtn {
  background-color: #073980;
  text-align: center;
  border: none;
  color: white;
  padding: 10px 40px;
  font-weight: 600;
  border-radius: 30px;
}
.pathakjibtn:hover{
  background-color: #e0e0d7;
  color: #000;
  font-weight: 800 !important;
}

.socialmediaicons {
  background-color: #073980;
  padding: 10px 10px;
  color: white !important;
}

.socialmediaicons:hover {
  background-color: #e0e0d7;
  color: #000 !important;
}

/*--------------------------------------------------------------
# Appointment Section
--------------------------------------------------------------*/
.makeandappointmentbg {
  background-color: #073980;
  padding: 40px 0px;
  color: #fff;
}
.appointmentheading{
    color: #fff;
    font-family: Helvetica;
    font-weight: 600;
}
h6{
  font-family: Helvetica;
  font-weight: 600 !important;
}
.custom-select {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}
/* input[type="file"] {
  display: none;
} */
.custom-file-upload {
  display: inline-block;
  background-color: #073980;
  border: 1px solid #e0e0d7 !important;
  color: #fff;
  padding: 8px 12px;
  border-radius:8px;
  width: 100%;
  text-align: left;
}
.custom-file-upload:hover {
  background-color: #e0e0d7;
  color: #000;
}
.custom-select option {
  background-color:#e0e0d7;
  color: #000;
  padding: 10px;
}
.appointment .php-email-form {
  width: 100%;
}
.appointment .php-email-form .form-group {
  padding-bottom: 8px;
}

.appointment .php-email-form input,
.appointment .php-email-form textarea,
.appointment .php-email-form select {
  color: var(--default-color);
  background-color: transparent;
  border-color: #e0e0d7;
  border-radius: 10px;
  box-shadow: none;
  font-size: 14px;
  padding: 12px !important;
}

.appointment .php-email-form input:focus,
.appointment .php-email-form textarea:focus,
.appointment .php-email-form select:focus {
  border: 1px solid #e0e0d7;
  color:#fff!important;
}

.appointment .php-email-form input:hover,
.appointment .php-email-form textarea:hover {
  border: 1px solid #e0e0d7;
  color:#fff !important;
}
.appointment .php-email-form select:hover {
  border: 1px solid #e0e0d7;
  color:#000 !important;
}
.appointment .php-email-form input::placeholder,
.appointment .php-email-form textarea::placeholder,
.appointment .php-email-form select::placeholder {
  color: #fff;
}

.appointment .php-email-form input::placeholder:hover,
.appointment .php-email-form textarea::placeholder:hover,
.appointment .php-email-form select::placeholder:hover {
  color: #000 !important;
}

.appointment .php-email-form input {
  color: #fff;
  height: 44px;
}



.appointment .php-email-form select {
  background-color: #073980;
  color: #fff;
  height: 44px;
}

.appointment .php-email-form select:hover {
  background-color: #e0e0d7;
  color: #000;
  border: 0px;

}

.appointment .php-email-form textarea {
  color: #fff;
  padding: 10px 12px;
}

.makeappointmentbtn {
  text-align: center;
  background-color: #e0e0d7;
  border: none;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 25px;
  border-radius: 30px;
}

.makeappointmentbtn:hover {
  font-weight: 800;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  color: #fff !important;
}

/* Position your custom icon */
.custom-clock-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.bghowwework {
  background: #e0e0d7;
  padding: 30px 0px !important;
  display: block !important;
}
.pathakjiintro {
  font-family: Inter;
  line-height: 1.6;
}

.hwwheading {
  color: #073980; 
  font-weight: bold;
}

.howweworkimg {
  transition: transform 0.3s ease;
}

.howweworkimg:hover {
  transform: scale(1.1); 
}

.box {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.box img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
}




/* counts */
.counter-icon {
  font-size: 60px;
}

.count-number {
  font-size: 44px;
  padding-top: 20px;
}

.count-text {
  font-size: 28px;
  padding-top: 15px;
}

.quote-icon-feedback {
  font-size: 30px;
  color: #073980;
}

/*--------------------------------------------------------------
# Departments Section
--------------------------------------------------------------*/
.departments {
  overflow: hidden;
}

.departments .nav-tabs {
  border: 0;
}

.departments .nav-link {
  border: 0;
  transition: 0.3s;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  font-family: Helvetica;
}

.departments .nav-link:hover {
  border: 1px solid #073980 !important;
  color: #000 !important;
  background: #e0e0d7 !important;
  padding: 10px 10px !important;
}

.departments .nav-link.active {
  border: none !important;
  color: #fff !important;
  background: #073980 !important;
  padding: 10px 10px !important;
}

.departments .tab-pane.active {
  animation: fadeIn 0.5s ease-out;
}

.departments .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.departments .details p {
  color: #000;
}

.departments .details p:last-child {
  margin-bottom: 0;
}

.serviceheading{
  font-size: 30px;
  color: #073980;
  font-weight: 600 !important;
  font-family: Helvetica;
}
.virasat{
  font-size: 26px;
  font-weight: 600 !important;
  font-family: Helvetica;
}
.servicetext {
  font-size: 18px;
  font-family: Inter;
  line-height: 1.9;
}

.serviceimages {
  width: 500px !important;
  height: 350px !important;
}

/* .navtabbg{
  border: 1px solid #000;
} */
.serviceback {
  background: #e0e0d7;
  margin: 30px 0px 0px 0px;
  padding: 70px 0px 10px 0px;
}

.servicemabtn {
  color: #fff;
  background: #073980;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 40px;
  margin-top: 30px;
}

.counter .count-number {
  font-size: 2rem;
  font-weight: bold;
  position: relative;
}

.counter .plus-sign {
  font-size: 44px;
  /* Adjust as needed */
  font-weight: 800;
  margin-left: 0.1rem;
  /* Adjust spacing between number and plus sign */
}

@media (max-width: 992px) {
  .departments .nav-link {
    border: 0;
    padding: 15px;
  }

  .departments .nav-link.active {
    border: none !important;
    color: #fff !important;
    background: #073980 !important;
    padding: 10px 10px !important;
  }
}
/* login register */
.registerinputs{
  border-radius: 10px !important;
  background: transparent !important;
}
.registerinputs:hover{
  border: 1px solid #073980;
  border-radius: 10px !important;
  background: transparent !important;
}
/*--------------------------------------------------------------
# Faq Section
--------------------------------------------------------------*/
.faqbg {
  background: #e0e0d7;
  margin-bottom: 60px;
  padding: 100px 0px;
}

.faq .faq-container .faq-item {
  background-color: #fff;
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.faq .faq-container .faq-item:last-child {
  margin-bottom: 0;
}

.faq .faq-container .faq-item h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  color: #073980;
}

.faq .faq-container .faq-item .faq-content {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

.faq .faq-container .faq-item .faq-content p {
  margin: 15px 0 0 0;
  color: #fff;
}

.faq .faq-container .faq-item .faq-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 0;
  transition: color 0.3s ease;
  color: #000;
}

.faq .faq-container .faq-item .faq-toggle:hover {
  color: #fff;
}

.faq .faq-container .faq-active {
  background-color: #073980;
}

.faq .faq-container .faq-active h3 {
  color: #fff;
}

.faq .faq-container .faq-active .faq-content {
  display: block;
  opacity: 1;
  max-height: 500px;
  color: #fff;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

/* cart*/

.custom-cart-header {
  background-color: #073980 !important;
  color: #fff !important;
  font-size: 20px;
  font-family: Helvetica, Arial, sans-serif;
}

/* Image size adjustments */
.cartpimg {
  width: 70px;
  height: 70px;
}

/* Responsive image sizing */
.cartpimg {
  max-width: 100%;
  height: auto;
}

/* Button styling */
.checkout-btn {
  background-color: #073980 !important;
  border-radius: 30px !important;
  font-size: 1.2rem !important;
  padding: 10px 50px !important;
  color: #fff;
  text-decoration: none;
  font-weight: 600 !important;
}

/* Media Queries for mobile devices */

@media (max-width: 576px) {
  .cartpimg {
    width: 50px;
  }
  .bghowwework{
    display: none !important;
  }
  .checkout-btn {
    width: 100%;
    font-size: 1rem;
  }
  
  .table th, .table td {
    font-size: 0.9rem;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .cartpimg {
    width: 70px;
  }
  
  .checkout-btn {
    font-size: 1.1rem;
  }
 
}


.checkout {
  border: none;
  padding: 10px 40px;
  background-color: #073980 !important;
  font-size: 20px;
  color: #fff;
  border-radius: 40px;
  font-weight: bold;
  font-family: Helvetica;
}

.checkout:hover {
  background-color: #e0e0d7 !important;
 color: #000;
 border: none;
}
.cehckoutinput:hover{
  border: 1px solid #073980;
}
/* gallery */
.gallery-col {
  display: flex;
  flex-direction: column;
}
.gallery-item {
  height: 400px;
  margin-bottom: 30px;
}
.gallery-img {
  flex: 1;
  height: 400px;
  object-fit: cover;
}

.gallery-item:hover .shop-now {
  display: block;
}
.gallery-item:hover .gallery-img {
  opacity: 0.7;
}
/* media nad news */
.media-item {
  height: 400px;
  margin-bottom: 30px;
 
}
/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item+.info-item {
  margin-top: 40px;
}

.contact .info-item i {
  color: #000;
  background: #e0e0d7;
  font-size: 20px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h3 {
  font-family: Helvetica;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.contact .info-item p {
  font-family: Inter;
  padding: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.contact .php-email-form {
  height: 100%;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 20px;
  box-shadow: none;
  border-radius: 0;
  color: #000 !important;
  background-color: #e0e0d7;
  border-color: none;
  border-radius: 20px;
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color:#e0e0d7;
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: #000;
}

.contact .php-email-form button[type=submit] {
  color: #000;
  background: #e0e0d7;
  font-family: Helvetica;
  font-weight: 600;
  border: 0;
  padding: 10px 36px;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
 font-weight: 800;
}
.contactbg{
  background: #073980;
  padding-top: 100px;
  padding-bottom: 80px;
}
.viewmorebtn{
  background: #073980;
  color: #fff;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 600;
  border: none;
  border-radius: 40px;
  padding: 10px 40px;
  margin: 30px 0px;
}
.viewmorebtn:hover{
  background: #e0e0d7;
  color: #000;
  font-weight: 800;
}
/* marques */

/* doctors */
.name{
  padding-top: 15px !important;
  color: #073980;
  font-weight: 600 !important;
  font-size: 20px;
  font-family: Helvetica;
}
.rounded-circle {
  background: #073980;
  padding: 5px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.box .rounded-circle {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.box:hover .rounded-circle {
  transform: scale(1.1);
}
@media (max-width:767px) {
  .team-boxed h2 {
    margin-bottom:25px;
    padding-top:25px;
    font-size:24px;
  }
}
 .item {
  text-align:center;
}
.title {
  text-align: center;
  text-transform:uppercase;
  font-weight:bold;
  color:#073980;
  font-size:15px;
}
.description {
  font-family: Inter;
  text-align: justify;
  font-size:16px;
  margin-top:5px;
  margin-bottom:20px;
}
.sticky-contact-icon {
  position: fixed;
 
  bottom: 20px; 
  right: 20px; 
  z-index: 1000; 
}
.top-icon {
 color:#000;
 font-size: 30px;  
 transition: transform 0.3s ease;
}
.contact-icon-wrapper:hover .contact-icon {
  transform: scale(1.1); 
}

.open{
  display: block !important;
}

.marquee-container {
  height: 50px; /* Adjust based on your design */
  overflow: hidden;
  margin-top: 15px !important; 
}
.marquee-content {
  width: 100%;
  animation: marquee 5s linear infinite;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.marquee-content p {
  margin: 0;
  padding: 0;
}

@keyframes marquee {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(-100%);
  }
}

/* mobile responsive  */
@media (max-width: 576px) {
  #someteam{
    padding-top: 25px !important;
  }
  .firstcardquick{
    display: block !important;
  }
  .quickinfobtn {
    padding: 10px 50px;
  } 
  .quickcard2{
    padding-left: 0px !important;
  } 
.col-lg-6{
  padding-left: 0px;
}
.col-md-12{
padding-left: 0px !important;
}
.serviceheading {
  font-size: 18px !important;

}
.details{
  padding: 25px !important;
  text-align: justify;
}
.serviceimages {
  width: 500px !important;
  height: 300px !important;
}
.leftspace{
  padding: 20px !important;
}
.item{
  padding-bottom: 30px !important;
}
.appointmentinputrow{
  padding: 25px !important;
}
.aboutheading{
  padding: 10px !important;
  font-size: 20px !important;
}
.aboutheading1{
  font-size: 24px !important;
  padding: 15px !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.counter-icon {
  font-size: 60px;
}
.count-number {
  font-size: 44px;
  padding-top: 5px;
}
.count-text {
  font-size: 28px;
  padding-top: 1px;
}
.quote-icon-feedback {
  font-size: 30px;
  color: #073980;
}
.counter{
  padding-bottom: 30px !important;
}
.feedbackitems{
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.footer {
  padding: 10px !important;
}
.spacecol{
  display: none !important;
}
.servicetext {
 padding: 0px 10px !important;
}
.serviceheading{
  padding-left: 8px;
}
.serviceappoitmentbtn{
  margin-bottom: 50px !important;
}
.inputspace{
  padding-left: 10px !important;
}
.aboutp{
  padding: 0px 25px !important;
}
.aboutvideo{
  width: 320px !important;
  height: 200px !important;
}
.orderdate{
  padding-top: 30px !important;
}
.checkout{
  font-size: 16px !important;
  padding: 10px 20px !important;
  margin-top: 0px !important;
}
.contactusfrom{
  padding: 10px !important;
}
.colinmobile{
  padding-left: 10px !important;
}
.cta-btn {
  margin-right: 45px !important;
}
.maincontainer {
  height: 100vh !important;
  padding-top: 400px !important;
  padding-bottom: 400px !important;
}
.medidetailproduct {
  width: 100%;
  max-width: 230px;
  max-height: 400px;
  height: auto !important;
  transition: all 0.5s ease;
  object-fit: cover;
}
.medicinetitle{
  font-size: 26px !important;
  font-family: Helvetica;
  padding-left: 10px;
 }
 .medidetailtext{
  text-align: justify;
 }
.addtocart {
  background: #073980;
  color: #fff;
  font-weight: 600;
}
.similarp{
  font-size: 26px !important;
  padding-left: 20px !important;
}
.bannerimges{
  height: 150px !important;
  width: 100%;
}
}
/* opd slip */
.opdspan{
  font-size: 14px !important;
  color: #000;
}
.opdmainh{
  font-family: Helvetica;
  font-size: 16px !important;
  font-weight: 600 !important;
}
#datesopd{
  display: none !important;
}
#datelopd{
  display: block;
}
.opdsubh{
  font-family: Helvetica;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.eopd{
  font-size: 14px !important;
 
}
.aptpcontact{
  display: block;
}
.aptpcontactm{
  display: none;
}
.opdbg{
  background: #e0e0d7;
  
}
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
 .opdmainh{
  font-size: 16px !important;
 }
 .opdspan{
  font-size: 14px;
  color: #000;
}
#datelopd{
  display: none !important;
}
#datesopd{
  display: block !important;
}
.opdsubh{
  font-size: 14px !important;
}
.eopd{
  font-size: 12px !important;
}
#apt{
display: none;
}
.aptp{
  font-size: 12px !important;
}
.aptpcontact{
  display: none;
}
.aptpcontactm{
  display: block;
}
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .opdmainh{
      font-size: 14px !important;
     }
     .opdspan{
      font-size: 12px;
      color: #000;
  }
  #datelopd{
      display: none !important;
  }
  #datesopd{
      display: block;
  }
}

.with-line {
  position: relative;
}

.with-line::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: #000;
  z-index: -1;
}
.react-datepicker-wrapper{
  width: 100% !important;
}
/* Responsive adjustments */
@media (min-width: 768px){
  .servicescard {
    max-height: 200px; /* Adjust image size for medium screens */
  }
  .socail-image {
    max-height: 200px;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  /* .sliderimages{
    height: 320px !important;
  } */
  .firstcardquick{
    display: block !important;
  }
  .quickinfobtn {
    padding: 5px 30px;
  } 
  .quickcard2{
    padding-left: 0px !important;
  } 
.quickinfo .quickinfo-item .icon i {
  font-size: 24px;
}

.quickinfo .quickinfo-item h3 {
  font-weight: 600;
  margin: 8px 0 8px 0;
  font-size: 20px;
}
.quickinfo .quickinfo-item p {
  line-height: 1.5;
}
.quickinfobtn {
  padding: 5px 35px;
  font-size: 20px;
}
.aboutheading1{
  padding-top: 20px !important;
  font-size: 28px;
}
.aboutheading {
  padding-top:30px !important ;
  font-size: 24px;
}
.hwwheading {
  font-size: 20px !important ;
}
.howweworkimg{
  width: 100px !important;
}
#howweworkimgtreatment{
  width: 300px !important;
}
#tabspace{
  padding: 0px !important;
}
.count-text {
  font-size: 20px;
}
.counter-icon {
  font-size: 50px;
}
.count-number {
  font-size: 32px;
}
.topcard {
  width: 18rem !important;
  height: 21rem !important;
  border-top: 1rem solid #073980 !important;
}
.rating{
  padding: 0px !important;
}
.serviceheadingfeed {
  font-size: 18px !important;
  padding-top: 15px !important;
}
.serviceimages {
  padding-top: 20px !important;
  width: 300px !important;
  height: 300px !important;
}
#migrainecol{
  text-align: center !important;
}
.aboutvideo{
  width: 430px !important;
  height: 500px !important;
}

.whychoossubheading{
  padding: 0px !important;
  margin-top: 10px !important;
}
.gallery-img {
  flex: 1;
  height: 300px;
  object-fit: cover;
}
.maincontainer {
  height: 100vh;
  background: url('./Assets/images/loginbcover.webp') no-repeat center center/cover;
}
}
@media (max-width: 1280px) and (max-height: 800px) {
  .header .cta-btn {
    margin-right: 10px;
  }
  
.header .branding {
  min-height: 50px;
  padding: 8px 0;
}
.logo {
  max-height: 82px;
}
.cta-btn {
  font-size: 14px;
  padding: 8px 22px;
  margin-left: 25px;
}
.navmenu .mymenu {
  font-size: 18px;
  font-weight: 400;
}
/* .sliderimages{
  height: 340px !important;
} */
.firstcardquick{
  display: block !important;
}
.quickinfobtn {
  padding: 6px 30px;
} 
.quickcard2{
  padding-left: 0px !important;
} 
.quickinfo .quickinfo-item .icon i {
font-size: 26px;
}
.quickinfo .quickinfo-item h3 {
font-weight: 600;
margin: 8px 0 8px 0;
font-size: 20px;
}
.quickinfo .quickinfo-item p {
line-height: 1.5;
}
.quickinfobtn {
padding: 5px 35px;
font-size: 20px;
}
.aboutheading1{
font-size: 28px;
}
.aboutheading {
padding-top:30px !important ;
font-size: 24px;
}
#tabspace{
  padding: 0px !important;
}
.count-text {
  font-size: 20px;
}
.counter-icon {
  font-size: 50px;
}
.count-number {
  font-size: 32px;
}
.topcard {
  width: 20rem !important;
  height: 21rem !important;
  border-top: 1rem solid #073980 !important;
}
.rating{
  padding: 0px !important;
}
.serviceheadingfeed {
  font-size: 18px !important;
  padding-top: 15px !important;
}
.serviceimages {
  padding-top: 20px !important;
  width: 300px !important;
  height: 300px !important;
}
#migrainecol{
  text-align: center !important;
}
.aboutvideo{
  width: 480px !important;
  height: 500px !important;
}

.whychoossubheading{
  padding: 0px !important;
  margin-top: 10px !important;
}
.gallery-img {
  flex: 1;
  height: 300px;
  object-fit: cover;
}
/* #registertab{
  margin-top: 700px !important;
  margin-bottom: 600px !important;
} */
.maincontainer {
  height: 100vh;
  background: url('./Assets/images/loginbcover.webp') no-repeat center center/cover;
}
.hwwheading {
  font-size: 20px !important ;
}
.howweworkimg{
  width: 100px !important;
}
#howweworkimgtreatment{
  width: 220px !important;
}
.medidetailproduct {
  width: 100%;
  max-width: 280px;
  max-height: 480px;
  height: auto !important;
}
.medicinetitle{
  font-size: 24px;
 }
}

.submitbtndonation{
  background-color: #073980;
  color: #fff;
  font-size: 18px;
  padding: 6px 8px;
  border: none;
  border-radius: 5px;
}
.addbtn{
  background: #073980 !important;
  color: #fff !important;
  margin-bottom: 30px;
}
.becomemember{
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  color:#000;
}
.mtitle{
  color: #000000 !important;
}
